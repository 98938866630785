import React, { useState, useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { HomeTab } from "./HomeTab";
import loadingGif from "../images/loading.gif";

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <img src={loadingGif} alt="Loading" style={{ width: "100px" }} />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/tab" element={<HomeTab />} />
            <Route path="*" element={<Navigate to={"/tab"} />} />
          </Routes>
        </Router>
      )}
    </>
  );
}
