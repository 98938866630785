import React, { useState, useEffect } from "react";
import { useTeams } from "msteams-react-base-component";
import Tickets  from "./Tickets";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Hybr1dInfo from "./Hybr1dInfo";
import CreateTicketModal from "./CreateTicketModal";

const API_URL = "https://pro-ms-tab.usehybrid.co/api"

const TicketCategories = [
	{
		"id": "Access Request",
		"name": "Access Request",
	},
	{
		"id": "Helpdesk",
		"name": "Helpdesk",
	},
	{
		"id": "Laptop Issue",
		"name": "Laptop Issue",
	},
	{
		"id": "Offboarding",
		"name": "Offboarding",
	},
	{
		"id": "Onboarding",
		"name": "Onboarding",
	},
	{
		"id": "Procurement",
		"name": "Procurement",
	},
	{
		"id": "Security",
		"name": "Security",
	},
  {
		"id": "MDM",
		"name": "MDM",
	},
];

export const HomeTab = () => {
  const [{ context }] = useTeams();
  const [user, setUser] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  const [tickets, setTickets] = useState([]);


  const createTicket = async () => {
    if (!title) {
      toast.error("Title is required");
      return;
    }

    if (!description) {
      toast.error("Description is required");
      return;
    }

    if (!category) {
      toast.error("Category is required");
      return;
    }

    const ticket = {
      userId: user?.id,
      title,
      description,
      name: user?.name,
      createdBy: user?.email,
      tenantId: user?.tenantId,
      category
    };

    try {
      const resp = await axios.get(`${API_URL}/verify-email/${user?.email}`);
      const { isValid = false, company = {} } = resp?.data || {};
      if (!isValid) {
        toast.error(
          "You are not authorized to create support tickets. Please contact IT Support."
        );
        return;
      }
      if (Object.keys(company || {}).length !== 0) {
        ticket["company"] = company;
      }
      await axios.post(`${API_URL}/tickets`, ticket, { headers: {"Content-Type": "application/json" }});
      toast.success("Ticket created successfully!");
      fetchTickets(user?.id);
      setTitle("");
      setDescription("");
      setCategory("");
    } catch (error) {
      console.log("An error occurred while creating the ticket:", error);
      toast.error("Failed to create ticket");
    }

    setIsModalOpen(false);
  };

  const fetchTickets = async (userId) => {
    try {
      const response = await axios.get(
        `${API_URL}/tickets/${userId}`
      );
      console.log("Fetched tickets:", response.data);
      setTickets(response.data);
    } catch (error) {
      console.log("An error occurred while fetching tickets:", error);
      toast.error("Failed to fetch tickets");
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (context) {
      let profileName =
        context.user?.loginHint &&
        context.user?.loginHint.slice(0, context.user?.loginHint.indexOf("@"));
      profileName =
        profileName &&
        profileName.charAt(0).toUpperCase() + profileName.slice(1);
      setUser({
        id: context.user?.id,
        email: context.user?.loginHint,
        name: profileName,
        tenantId: context.user?.tenant?.id,
      });
      fetchTickets(context.user?.id);
    }
  }, [context]);

  return (
    <div className="container">
      <ToastContainer />

      {/* Hybr1d Info */}
      <Hybr1dInfo user={user} toggleModal={toggleModal} />

      {/* Create Tickets */}
      <CreateTicketModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        createTicket={createTicket}
        category ={category}
        setCategory ={setCategory}
        categoryOptions={TicketCategories}
      />

      {/* Show Tickets */}
      <Tickets user={user} tickets={tickets} fetchTickets={fetchTickets} />
    </div>
  );
};

