import React from "react";
import { Modal } from "react-bootstrap";
import logoImg from "../images/icon-color.png";

const CreateTicketModal = ({
  isModalOpen,
  toggleModal,
  title,
  setTitle,
  description,
  setDescription,
  createTicket,
  category,
  categoryOptions,
  setCategory
}) => {
  return (
    <div>
      <Modal show={isModalOpen} onHide={toggleModal}>
        <Modal.Header>
          <img
            src={logoImg}
            alt="Logo"
            style={{ maxWidth: "50px", maxHeight: "50px" }}
          />
          <Modal.Title>
            <span className="ml-2 mt-2 font-weight-bold">Create Ticket</span>
          </Modal.Title>
          <button className="btn btn-danger" onClick={toggleModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group my-2">
            <h6 className="font-weight-bold">Operational Hours</h6>
            <span>Monday to Friday: 9:30AM to 8:30PM(SGT)</span>
            <br />
            <span>Saturday and Sunday: 9:30AM to 4:30PM(SGT)</span>
          </div>
          <hr /> */}
          
          <div className="form-group my-2">
            <label htmlFor="title" className="font-weight-bold">
              Subject:
            </label>
            <input
              type="text"
              className="form-control my-2"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="What is your query about?"
            />
          </div>
          <div>
          <label htmlFor="category" className="font-weight-bold">
              Category:
            </label>
            <select
              className="form-control my-2"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select a category
              </option>
              {categoryOptions.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="description" className="font-weight-bold">
              Please describe your issue:
            </label>
            <textarea
              className="form-control my-2"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="What can we help you with?"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={createTicket}>Create</button>
          <button className="btn btn-secondary" onClick={toggleModal}>Cancel</button>
        </Modal.Footer>
      </Modal>
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default CreateTicketModal;
