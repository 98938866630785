import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Modal,
  Card,
  Container,
  Row,
  Col,
  Badge,
  Stack,
} from "react-bootstrap";
import logoImg from "../images/icon-color.png";
import "./tickets.css";

const API_URL = "https://pro-ms-tab.usehybrid.co/api";

const Tickets = ({ user, tickets, fetchTickets }) => {
  const [replyText, setReplyText] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [ticketToClose, setTicketToClose] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const repliesPerPage = 5;

  const handleAttachmentClick = (attachment) => {
    setSelectedAttachment(attachment);
    setShowAttachmentModal(true);
  };

  const handleCloseModal = () => {
    setShowAttachmentModal(false);
    setSelectedAttachment(null);
  };

  const closeTicket = (ticketId) => {
    setTicketToClose(ticketId);
    setShowConfirmationModal(true);
  };

  const confirmCloseTicket = async () => {
    setShowConfirmationModal(false);

    try {
      await axios.get(`${API_URL}/close/${ticketToClose}`);
      toast.success("Ticket closed successfully!");
      await fetchTickets(user?.id);
    } catch (error) {
      console.error(error);
      toast.error("Failed to close ticket");
    }
  };

  const cancelCloseTicket = () => {
    setShowConfirmationModal(false);
    setTicketToClose(null);
  };

  const replyTicket = async (ticketId) => {
    if (!replyText) {
      toast.error("Reply is required");
      return;
    }

    const reply = {
      message: replyText,
      ticketId: ticketId,
      userId: user.id,
      name: user.name,
      createdBy: user.email,
    };

    try {
      await axios.post(`${API_URL}/reply/${ticketId}`, reply);
      await fetchTickets(user?.id);
      toast.success("Your reply has been saved!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to reply to the ticket");
    }

    toggleModal(ticketId);
  };

  const toggleModal = (ticketId) => {
    setSelectedTicketId(selectedTicketId === ticketId ? null : ticketId);
    setReplyText("");
    setStartIndex(0);
  };

  const handleReplyChange = (event) => {
    setReplyText(event.target.value);
  };

  const convertToRelativeTime = (dateString) => {
    const givenTime = new Date(dateString);
    const currentTime = new Date();
    const timeDifference = currentTime - givenTime;

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30 * 12));

    if (years > 0) {
      return years === 1 ? "a year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "a day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
      return "Just now";
    }
  };

  const getCurrentPageReplies = (ticket) => {
    const endIndex = startIndex + repliesPerPage - 1;
    return ticket.replies.slice(startIndex, endIndex + 1);
  };

  return (
    <Container>
      <h4 className="text-primary text-center">Tickets Dashboard 🎫</h4>
      {tickets.length === 0 ? (
        <h6 className="text-info text-center mt-4">
          No tickets registered! 🔍
        </h6>
      ) : (
        <Card className="p-4 shadow">
          <Row>
            {tickets.map((ticket) => (
              <Col md={4} key={ticket._id}>
                <Card className="mb-4">
                  <Card.Header className="text-center">
                    Ticket #<strong>{ticket.ticketNumber}</strong>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{ticket.title}</Card.Title>
                    <Card.Text>
                      <p
                        className={`${
                          ticket.status === "Closed"
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        Status: {ticket.status}
                      </p>
                    </Card.Text>
                    <Card.Text>
                      <strong>Description:</strong> {ticket.description}
                    </Card.Text>
                    <button
                      className="btn btn-outline-info mr-2"
                      onClick={() => toggleModal(ticket._id)}
                    >
                      View
                    </button>

                    <Modal
                      size="lg"
                      show={selectedTicketId === ticket._id}
                      onHide={() => toggleModal(ticket._id)}
                    >
                      <Modal.Header>
                        <img
                          src={logoImg}
                          alt="ZenAdmin"
                          style={{
                            maxWidth: "50px",
                            maxHeight: "50px",
                          }}
                        />
                        <Modal.Title>
                          Ticket <strong>#{ticket.ticketNumber}</strong>
                        </Modal.Title>
                        <button
                          className="btn btn-danger"
                          onClick={() => toggleModal(ticket._id)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col">
                            <p id="ticket-title" className="font-weight-bold">
                              Subject:
                            </p>
                            <span>{ticket.title}</span>
                          </div>
                          <div className="col">
                            <p
                              className={`${
                                ticket.status === "Closed"
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              Status:{" "}
                              {ticket.status === "Closed" ? (
                                <span>🔴 {ticket.status}</span>
                              ) : (
                                <span>🟢 {ticket.status}</span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="form-group mt-4">
                          <p
                            id="ticket-description"
                            className="font-weight-bold"
                          >
                            Description:
                          </p>
                          <span>{ticket.description}</span>
                        </div>
                        <hr />
                        <div className="form-group">
                          <h6 className="font-weight-bold mb-2">Replies: </h6>
                          <br />
                          {ticket.replies && ticket.replies.length === 0 ? (
                            <p>No Replies</p>
                          ) : (
                            getCurrentPageReplies(ticket).map((t) => (
                              <div key={t._id}>
                                <div className="row">
                                  <div className="col">
                                    <p>
                                      <strong>From: </strong>
                                      {ticket.requesterId === t.requesterId
                                        ? "Me 👤"
                                        : "Agent 👨‍💻"}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p>
                                      <strong>When: </strong>
                                      <span
                                        title={moment(t.createdAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}
                                      >
                                        {convertToRelativeTime(t.createdAt)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p>
                                      <strong>Message: </strong>
                                      <div
                                        className="scrollable-container"
                                        style={{ borderRadius: "2px" }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: t.message,
                                          }}
                                          style={{
                                            width: "100%",
                                            maxHeight: "300px",
                                            border: "0.5px solid #f8f9fa",
                                            padding: "5px",
                                          }}
                                        />
                                      </div>
                                    </p>
                                  </div>
                                </div>
                                {t?.attachments && t.attachments.length > 0 ? (
                                  <div className="row">
                                    <div className="col">
                                      <strong>Attachments:</strong>
                                      <Stack direction="horizontal" gap={4}>
                                        {t.attachments.map((ele, index) => {
                                          const { name = "" } = ele;
                                          return (
                                            <Badge
                                              key={index}
                                              onClick={() =>
                                                handleAttachmentClick(ele)
                                              }
                                              bg="light"
                                              as={"div"}
                                              style={{ marginRight: "4px" }}
                                            >
                                              {name}
                                            </Badge>
                                          );
                                        })}
                                      </Stack>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <hr />
                              </div>
                            ))
                          )}

                          {Math.ceil(ticket.replies.length / repliesPerPage) >
                            1 && (
                            <div className="row">
                              <div className="col-2">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  disabled={startIndex === 0}
                                  onClick={() =>
                                    setStartIndex(startIndex - repliesPerPage)
                                  }
                                >
                                  {"<<"}
                                </button>
                              </div>
                              <div className="col-8">
                                <p className="text-center">
                                  Showing {startIndex + 1} -{" "}
                                  {Math.min(
                                    startIndex + repliesPerPage,
                                    ticket.replies.length
                                  )}{" "}
                                  out of {ticket.replies.length} Replies <br />{" "}
                                  Page{" "}
                                  {Math.ceil((startIndex + 1) / repliesPerPage)}{" "}
                                  out of{" "}
                                  {Math.ceil(
                                    ticket.replies.length / repliesPerPage
                                  )}
                                </p>
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  disabled={
                                    Math.ceil(
                                      (startIndex + 1) / repliesPerPage
                                    ) ===
                                    Math.ceil(
                                      ticket.replies.length / repliesPerPage
                                    )
                                  }
                                  onClick={() =>
                                    setStartIndex(startIndex + repliesPerPage)
                                  }
                                >
                                  {">>"}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        {ticket.status !== "Closed" && (
                          <div>
                            <hr />
                            <div className="form-group">
                              <label
                                htmlFor="ticket-reply"
                                className="font-weight-bold"
                              >
                                Please type your reply -
                              </label>
                              <textarea
                                className="form-control bg-transparent my-2"
                                name="reply"
                                placeholder="Write something"
                                value={replyText}
                                onChange={handleReplyChange}
                              />
                            </div>
                            <Modal.Footer>
                              <button
                                className="btn btn-success"
                                onClick={() => replyTicket(ticket._id)}
                              >
                                Reply
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => toggleModal(ticket._id)}
                              >
                                Cancel
                              </button>
                            </Modal.Footer>
                          </div>
                        )}
                      </Modal.Body>
                    </Modal>

                    {ticket.status !== "Closed" && (
                      <button
                        className="btn btn-danger"
                        onClick={() => closeTicket(ticket._id)}
                      >
                        Close
                      </button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      )}
      <Modal show={showConfirmationModal} onHide={cancelCloseTicket}>
        <Modal.Header>
          <img
            src={logoImg}
            alt="ZenAdmin"
            style={{ maxWidth: "50px", maxHeight: "50px" }}
          />
          <Modal.Title>Close Ticket?</Modal.Title>
          <button className="btn btn-danger" onClick={cancelCloseTicket}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to close this ticket?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={cancelCloseTicket}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={confirmCloseTicket}>
            Close Ticket
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAttachmentModal}
        onHide={handleCloseModal}
        size="lg"
        dialogClassName="custom-modal-dialog"
      >
        <Modal.Header className="custom-modal-dialog">
          <Modal.Title>
            {selectedAttachment?.name}{" "}
            {selectedAttachment?.name.split(".").reverse()[0]}
          </Modal.Title>
          <button className="btn btn-danger" onClick={handleCloseModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="custom-modal-dialog">
          {["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "txt"].includes(
            selectedAttachment?.name.split(".").reverse()[0]
          ) ? (
            <embed
              src={selectedAttachment?.attachment_url}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          ) : ["mp4", "mov", "avi", "flv", "wmv", "webm"].includes(
              selectedAttachment?.name.split(".").reverse()[0]
            ) ? (
            <video
              src={selectedAttachment?.attachment_url}
              alt={selectedAttachment?.name}
              style={{ width: "100%", height: "auto" }}
              controls={true}
              autoPlay={true}
            />
          ) : ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(
              selectedAttachment?.name.split(".").reverse()[0]
            ) ? (
            <img
              src={selectedAttachment?.attachment_url}
              alt={selectedAttachment?.name}
              style={{ width: "100%", height: "auto" }}
            />
          ) : ["mp3", "wav", "ogg", "m4a", "flac"].includes(
              selectedAttachment?.name.split(".").reverse()[0]
            ) ? (
            <audio
              src={selectedAttachment?.attachment_url}
              alt={selectedAttachment?.name}
              style={{ width: "100%", height: "100px" }}
              controls={true}
              autoPlay={true}
            />
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Tickets;
