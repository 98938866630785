import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import itImg from "../images/it-page.png";

const Hybr1dInfo = ({ user, toggleModal }) => {
  return (
    <Container fluid className="py-5">
      <Card className="p-4 shadow">
        <Row>
          <Col xs={12} md={6}>
            <h3 className="card-title mb-4 text-primary">
              <h4 className="d-sm-none text-center">ZenAdmin IT Support! 🚀</h4>
              <h3 className="d-none d-sm-block">ZenAdmin IT Support! 🚀</h3>
            </h3>
          </Col>
          <Col xs={12} md={6} className="text-md-right text-center">
            <button className="btn btn-success" onClick={toggleModal}>
              Create a Ticket
            </button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card.Body>
              <p className="card-text text-black">
                Hey, <span className="font-weight-bold">{user?.name}</span>!
                Welcome to ZenAdmin IT Support! 👋
              </p>
              <p className="card-text">
                Please feel free to create a ticket for any IT support issues, We provide assistance in the following areas:
              </p>
              <ul className="list-group list-group-flush mb-4">
                <li className="list-group-item">
                  ➼ Setting upon configuring work apps or tools 🔧
                </li>
                <li className="list-group-item">
                  ➼ Assistance with device login credentials or lockouts 🔑
                </li>
                <li className="list-group-item">
                  ➼ Troubleshooting software issues (e.g. email syncing, attachments, pop-up ads) 🐛
                </li>
                <li className="list-group-item">
                  ➼ Resolving hardware issues (e.g. device not turning on, laptopshutdowns, audio problems) 💻
                </li>
              </ul>
              <p className="card-text">
                Think of us as your remote IT department - we are ready to help you with anything you need to make work happen 🚀
              </p>

              {/* <hr className="my-4" />

              <h6>Operational Hours</h6>
              <Row>
                <Col xs={6}>
                  <p className="text-muted">
                    Monday to Friday
                    <br />
                    9:30AM to 8:30PM(SGT)
                  </p>
                </Col>
                <Col xs={6}>
                  <p className="text-muted">
                    Saturday and Sunday
                    <br />
                    9:30AM to 4:30PM(SGT)
                  </p>
                </Col>
              </Row> */}
            </Card.Body>
          </Col>
          <Col xs={12} md={6}>
            <Card.Body>
              <Card.Img src={itImg} alt="ZenAdmin" className="img-fluid" />
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Hybr1dInfo;
